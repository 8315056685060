.student-grade-view .menu-area {
  display: flex; /* Enables flexbox layout */
  justify-content: center; /* Centers flex items (buttons) horizontally */
  align-items: center; /* Aligns items vertically at the center, if needed */
  flex-wrap: wrap; /* Allows items to wrap into multiple lines if not enough space */
  text-align: center;
}

.student-grade-view .menu-area button {
  width: 100px; /* Equal width and height make a circle */
  height: 100px;
  border-radius: 50%; /* Keeps the button circular */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Stacks content (including line breaks) vertically */
  margin: 10px;
  cursor: pointer;
  font-family: var(--font-secondary);
  color: white;
  background-color: var(--color2);
  border: none;
  outline: none;
  transition: background-color 0.3s;
}

.student-grade-view .menu-area button:hover,
.student-grade-view .menu-area button:focus {
  filter: brightness(90%);
}

.student-grade-view .menu-area button.selected {
  background-color: var(--color1);
}


.student-grade-view {
  text-align: center;
  padding: 20px;
}

.evaluation-menu {
  display: flex;
  flex-wrap: wrap; /* Ensures buttons stay side by side and wrap if needed */
  justify-content: center;
  gap: 10px; /* Space between buttons */
}

.comments-container {
  margin: 20px 0; /* Adds space around the comments section */
}

.overall-grade {
  background-color: white;
  box-shadow: var(--standard-box-shadow);
  font-family: var(--font-secondary);
  padding: 10px;
}

.comment-box {
  background-color: white; /* White background */
  box-shadow: var(--standard-box-shadow); /* Shadow styling */
  text-align: left;
  font-size: 18px;
  font-family: var(--font-secondary); /* Secondary font */
  padding: 10px; /* Spacing inside the box */
  border-radius: 5px; /* Optional: Slightly round the corners */
  line-height: 1.5; /* Improves readability for multiline comments */
  white-space: pre-wrap; /* Ensures line breaks are respected */
}


.evaluation-button {
  border-radius: 50px; /* Elliptical shape */
  padding: 10px 20px; /* Ensure buttons adjust to text length */
  font-family: var(--font-small-caps);
  font-size: 1rem;
  text-align: center;
  border: none;
  background-color: var(--color2); /* Color scheme from your CSS */
  color: white;
  box-shadow: var(--standard-box-shadow);
  transition: background-color 0.3s, color 0.3s;
  cursor: not-allowed;
  white-space: nowrap; /* Prevent text from wrapping */
}

.evaluation-button.clickable {
  background-color: var(--color1); /* Clickable buttons in color1 */
  cursor: pointer;
  color: white;
}

.evaluation-button.non-clickable {
  background-color: var(--color2verylight); /* Non-clickable buttons in light color */
  color: gray;
  cursor: not-allowed;
}

.evaluation-button:hover.clickable {
  background-color: var(--color1light); /* Lighten clickable button on hover */
}

h1 {
  font-family: var(--font-display); /* Custom font for header */
  font-size: 2.5rem;
  color: var(--color2);
}

.evaluation-submenu {
  display: flex; /* Arrange buttons in a row */
  gap: 10px; /* Add space between buttons */
  justify-content: center; /* Center align the buttons */
  margin-top: 15px; /* Optional: Add some space above the submenu */
  margin-bottom: 15px; /* Optional: Add some space below the submenu */
}

.evaluation-submenu button {
  padding: 10px 15px; /* Adjust padding for a consistent button size */
  border: none;
  border-radius: 5px;
  font-family: var(--font-small-caps);
  font-size: 1rem;
  background-color: var(--color2); /* Default color */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.evaluation-submenu button.selected {
  background-color: var(--color1); /* Highlight selected button */
}

.evaluation-submenu button:hover {
  background-color: var(--color1light); /* Slightly lighter hover color */
}

.grading-details{
  background-color: white;
  font-family: var(--font-secondary);
  box-shadow: var(--standard-box-shadow);
  font-size: 18px;
  text-align: left;
  padding: 20px;
}

