.gradingPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; /* Adds some space around the page content */
  background-color: var(--color2verylight); /* Sets the background color */
  min-height: 100vh; /* Ensures the page fills the viewport height */
}

nav {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adds spacing between the buttons */
  margin-bottom: 20px; /* Creates space between the menu bar and the components */
}


.gradingPageContainer > div {
  width: 100%;
  max-width: 1200px; /* Optional: Limit the component width */
  margin-top: 40px; /* Push the rendered components below the menu bar */
  padding: 20px; /* Adds spacing inside the component container */
  border-radius: 8px; /* Rounds corners for a smoother look */
}
