@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;700&ital,wght@0,1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;700&ital,wght@0,1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+SC:wght@400;700&ital,wght@0,1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;700&ital,wght@0,1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
  @import url("https://use.typekit.net/ubt1odf.css");


:root {
    --standard-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    --color1: #fe938c;
    --color1light: #ffd8d3;
    --color2: #4281a4;
    --color2light: #6ea6c5;
    --color2verylight: #ebf3f7;
    --color3: #fdd692;
    --color4: #9cafb7;
    --color5: #e6b89c;
    --container-background: #ffffff;
  --font-primary: 'calluna-sans', sans-serif;
  --font-secondary: 'Alegreya', serif;
  --font-small-caps: 'Alegreya SC', serif;
  --font-sans-small-caps: 'Alegreya Sans SC', sans-serif;
  --font-display: 'Sacramento', cursive;
  --font-mono: 'Courier Prime', sans;
}

body{
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 20px;
}

a {
    color: var(--color2); /* Sets the default color */
    text-decoration: none; /* Removes underline */
    transition: color 0.3s; /* Smooth transition for color change */
}

a:hover, a:active {
    color: var(--color1); /* Changes color on hover and click */
}


h1{
  color: var(--color2);
  font-family: var(--font-display);
  font-size: 60px;
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 10px;
}

h2{
  color: var(--color4);
  font-family: var(--font-secondary);
}

.app-container {
  display: flex;
  flex-direction: column;
  background-color: var(--container-background);
  min-height: 100vh;
  transition: background-color 3s;
}



.top-menu-container {
    background-color: var(--color1); /* Navy blue background */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px; /* Adjust padding as needed */
  position:fixed;
  z-index: 10;
}

.course-title-display {/* forthe case where there is no course description or syllabus and the title of the course is shown instead */
  font-family: var(--font-small-caps);
  font-variant: small-caps;
  font-size: 1.6rem;
  color: white;
  margin-left: 10px;
    padding: 5px 20px; /* Adjust padding as needed */
}

.top-menu-container button {
  color: white; /* White text color */
  background-color: inherit; /* Inherits navy blue from nav-bar */
  border: none; /* No border */
  padding: 10px 20px; /* Adjust padding as needed */
  text-decoration: none; /* Removes underline from links if used */
  border-radius: 50px; /* Elliptical shape */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for color and background */
  font-family: var(--font-small-caps);
  font-variant: small-caps;
  font-size: 1.2rem;
  margin-left: 2.5px;
  margin-right: 2.5px;
}


.top-menu-container button:hover, .top-menu-container button.selectedButton {
  background-color: white; /* White background */
  color: var(--color2); /* Text color of the nav bar's background */
}



.container-below-top-nav {
  display: flex;
  margin-top: 50px;
  padding: 0px;
  justify-content: center;
}


.left-menu-container {
  position: fixed;
  left: -250px; /* Match the width */
  top: 60px; /* Adjust based on your top menu height */
  padding-top: 50px;
  width: 250px;
  height: calc(100% - 60px);
  background-color: var(--color2);
  transition: left 0.3s ease-in-out;
  z-index: 9;
}

.left-menu-container.active {
  left: 0;
}





.myphi-menu button {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end; /* Align text to the right */
  padding: 5px 15px; /* Adjust padding as needed */
  margin-bottom: 5px; /* Space between buttons */
  border: none;
  border-radius: 20px 0 0 20px; /* Rounded corners on the left, squared off on the right */
  font-family: var(--font-secondary);
  font-size: large;
  font-weight: bold;
  color: white;
  background-color: transparent; /* Default background */
  transition: background-color 0.3s, color 0.3s;
  width: auto; /* Allow the button width to grow with content */
  text-align: right; /* Ensure text is aligned to the right */
}




.myphi-menu button:hover, .left-menu-container button.myPhiActiveButton {
  background-color: white; /* White background on hover */
  color: var(--color1); /* Font color changes on hover */
}


.myphi-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.myphi-container {
  display: flex;
  width: 100%;
  position: relative;
}



.content {
  max-width: 900px;
  padding: 20px;
  flex-grow: 1;
  margin: 0 auto;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 100%;
  padding-bottom: 50px;
  transition: margin-left 0.3s ease-in-out;
}


.content::-webkit-scrollbar {
  display: none;
}

.hamburger-icon {
  display: none;
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1001;
  cursor: pointer;
  width: 30px;
  height: 25px;
}

.hamburger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--color2);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.hamburger-icon span:nth-child(1) {
  top: 0px;
}

.hamburger-icon span:nth-child(2) {
  top: 10px;
}

.hamburger-icon span:nth-child(3) {
  top: 20px;
}

.hamburger-icon.open span:nth-child(1) {
  top: 10px;
  transform: rotate(135deg);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.hamburger-icon.open span:nth-child(3) {
  top: 10px;
  transform: rotate(-135deg);
}

@media (max-width: 768px) {
  .hamburger-icon {
    display: block;
  }

  .content {
    margin-left: 0;
    width: 100%;
  }
  .myphi-container .content {
    margin-left: 0;
    width: 100%;
  }
}

@media (min-width: 769px) {
 
  .myphi-container .content {
    margin-left: 15%;
    width: 85%;
  }

    .content {
    margin-left: 250px; /* Match the menu width */
  }

}




@media print {
  .app-container {
    min-height: auto;
  }
}