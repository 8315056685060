/* CourseDesignScheduleMaker.css */

/* Add left margin to the main container */
.course-design-schedule-maker {
  margin-left: 20px;
}

/* Adjust the schedule row */
.schedule-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Set widths and styles for items */
.course-date-item, .topic-item {
  box-sizing: border-box;
  margin-right: 10px;
  padding: 10px;
  padding-left: 20px; /* Left padding */
  background-color: white;
  box-shadow: var(--standard-box-shadow);
}

.course-date-item {
  width: 120px;
}

.topic-item {
  width: 600px;
  max-width: calc(100% - 130px);
  box-sizing: border-box;
  padding: 10px;
  padding-left: 20px; /* Left padding */
  background-color: white;
  box-shadow: var(--standard-box-shadow);
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align buttons at the end of the item */
}


.section-name-item {
  width: calc(720px + 10px); /* Combined width of courseDate and topic plus margin */
  max-width: 100%;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 20px; /* Left padding */
  background-color: var(--color2);
  color: white;
  box-shadow: var(--standard-box-shadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Adjust action buttons */
.action-button {
  background: white; /* White background */
  cursor: pointer;
  margin-left: 5px;
  padding: 5px;
  box-shadow: var(--standard-box-shadow);
}

/* Ensure icons match section name background color */
.action-icon {
  color: var(--color2); /* Icons match section name background color */
  font-size: 16px;
}


/* Adjust action buttons */
.action-buttons {
  display: flex;
  align-items: center;
}

.action-buttons button {
  background: white; /* White background to make icons visible */
  cursor: pointer;
  margin-left: 5px;
  padding: 5px;
}

.action-buttons button:hover {
  background-color: #f0f0f0;
}

.action-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Ensure icons are visible */
.action-buttons button i, .action-buttons button svg {
  color: black;
  font-size: 16px;
}

/* Style the create new item button */
.create-new-item-button {
  display: flex;
  align-items: center;
}

.create-new-item-button button {
  background: white;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.create-new-item-button button:hover {
  background-color: #f0f0f0;
}

.create-new-item-button button i, .create-new-item-button button svg {
  color: black;
  font-size: 16px;
}

/* Style the options menu */
.options-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: var(--standard-box-shadow);
  padding: 5px;
  margin-top: 5px;
  z-index: 100;
}

.options-menu button {
  display: block;
  width: 100%;
  background: none;
  border: none;
  padding: 5px;
  text-align: left;
  cursor: pointer;
}

.options-menu button:hover {
  background-color: #f0f0f0;
}

/* Style for the top text */
.top-text {
  margin-bottom: 20px;
}

.new-buttons-row {
  display: flex;
  gap: 10px; /* Adjust the gap between buttons as needed */
  justify-content: center;
}

/* button styling */

.new-section-header-button button,
.new-class-day-button button,
.cancel-add-new-item-button button {
  flex: 1;
  background-color: var(--color1);
  height: 26px; 
  display: flex; 
justify-content: center;
align-items: center; 
}

.move-to-here-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  background-color: var(--color1); /* Similar style to other buttons */
}
