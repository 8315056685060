.syllabus-link-adder {
  display: flex;
  gap: 20px; /* Add space between columns */
}

.syllabus-link-adder {
  align-items: flex-start; /* Top-justify content in the columns */
}

.left-column,
.right-column {
  max-height: 100%; /* Ensure columns respect the container height */
  overflow-y: auto; /* Allow independent vertical scrolling */
}



.left-column,
.right-column {
  flex: 1; /* Equal width for columns */
}

.topic-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto; /* Add scrolling if the list is long */
}

.topic-item {
  padding: 10px;
  font-family: var(--font-mono);
  font-size: 13pt;
  cursor: pointer;
  border-bottom: 1px solid #ddd; /* Light border between items */
}

.topic-item:last-child {
  border-bottom: none;
}

.topic-item:hover {
  background-color: #f0f0f0; /* Light hover effect */
}

.topic-item.selected {
  background-color: var(--color3);
  color: #fff; /* Optional: Make text color white when selected */
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link-icon.selected-icon {
  color: var(--color1);
}


.add-link-button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.link-editor {
  margin-top: 20px;
  padding: 15px;
  background-color: white;
  box-shadow: var(--standard-box-shadow);
  border-radius: 4px;
}

.icon-row {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
}

.link-icon {
  font-size: 20px;
  cursor: pointer;
  color: #555;
}

.link-icon:hover {
  color: var(--color3); /* Highlight color */
}

.url-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-family: var(--font-mono);
  font-size: 13pt;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.save-link-button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.link-icon.selected-icon {
  color: var(--color1);
}

.edit-link-button,
.delete-link-button,
.view-link-button,
.save-link-button {
  margin: 0 5px; /* Add left-right padding between buttons */
}




