#pdfButton {
    position: relative;
    padding-top: 10px;
  top: 30px; /* Moves the button down by 5px */
  right: 10px; /* Moves the button to the left by 10px */
  color: white; /* Set icon color to white */
    background-color: transparent; /* Ensure no background color */
  border: none;
  cursor: pointer;
  transition: color 0.1s ease, box-shadow 0.1s ease, transform 0.1s ease;
  outline: none;
  display: flex;
  top: calc(100% + 25px); /* Adjust this to position the button just below the menu bar */
}

#pdfButton:hover, #pdfButton:active {
  color: var(--color1); /* Changes icon color on hover */
  transform: scale(1.02);
}

#pdfButton i {
  transition: color 0.1s ease; /* Smooth color transition for the icon */
}

#pdfButton:hover i {
  color: var(--color1); /* Icon color change on hover */
}
