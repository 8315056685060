/* CourseDesignCourseDescription.css */

.course-description-container .ql-toolbar,
.course-description-container .ql-container {
    background-color: white;   /* White background for toolbar and editor */
    border-radius: 5px;        /* Optional: add rounded corners */
}

.course-description-container .ql-editor {
    font-size: 1.2em;          /* Increase text size */
    padding: 20px;             /* Add padding around text */
    min-height: 200px;         /* Set a minimum height for the text area */
    line-height: 1.6;          /* Improve readability */
}

.markdown-editor-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.editor-preview-wrapper {
    display: flex;
    gap: 20px;
}

.markdown-textarea {
    width: 50%;
    height: 600px;
    font-family: var(--font-mono);
    font-size: 16px;
    border: 1px solid #ccc;
    padding: 10px;
    resize: none;
}

.markdown-preview {
    width: 50%;
    height: 600px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
    font-family: var(--font-secondary);
    font-size: 16px;
}
