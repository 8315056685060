.syllabus{

  background-color: white;
  box-shadow: var(--standard-box-shadow);
}

/* Header styling */
.syllabus-header {
  padding-top: 10px;
  margin-bottom: 16px;
  text-align: center;
}

/* Content area styling */
.syllabus-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  font-family: var(--font-secondary);
}

/* Styling for each row in the syllabus */
.syllabus-row {
  display: flex;
  align-items: center;
  padding: 4px;
  padding-left: 20px;
  border-bottom: 0.2px solid #ddd; /* optional separator */
}

/* Styling for courseDate and topic items */
.syllabus-course-topic {
  display: flex;
  gap: 16px;
}

/* Styling for section names */
.syllabus-section {
  background-color: var(--color2);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  box-shadow: var(--standard-box-shadow);
}

.syllabus-link-icon{
  padding-left: 4px;
  padding-right: 4px;
}