.grading-format .radioButton {
  width: 100%;
  max-width: 30%;
  margin-bottom: 15px;
  padding: 15px;
  text-align: left;
  white-space: normal;
  line-height: 1.4;
}

.grading-format h2 {
  margin-bottom: 20px;
}

.grading-format .CourseDesignQuestion {
  margin-bottom: 30px;
}