form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

label {
    margin-bottom: 10px; /* Adjust as needed for space between label and input */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

input {
    font-family: var(--font-mono); /* Change to your preferred font */
    font-size: 18px;
    margin-top: 5px; /* Space between label and input field */
    padding: 10px; /* Adjust padding for more space inside the input */
}



.loginButton {
    background-color: var(--color2); /* Your color variable */
    color: white;
    border: 4px solid var(--color2); /* Border color same as background */
    border-radius: 20px; /* Gives the oval shape */
    font-family: var(--font-small-caps); /* Your font variable */
    font-size: 18px;
    padding: 8px 26px; /* Adjust padding as needed */
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover and click */
}

.loginButton:hover, .loginButton:active {
    background-color: white;
    color: var(--color2);
}

