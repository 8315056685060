.grading-fields {
  font-family: var(--font-secondary);
}

.grading-fields .field textarea {
  font-size: 14pt;
  font-family: var(--font-mono);
  outline: none;
  box-shadow: var(--standard-box-shadow);
  width: calc(100% - 10px); /* 5px margin on both sides */
  height: 50%; /* Initial height */
  resize: vertical; /* Allow resizing */
}

.grading-fields .running-grade {
  box-shadow: var(--standard-box-shadow);
  padding: 10px;
  margin-bottom: 20px;
}

.running-grade {
    text-align: center;
    margin-top: 10px;
    background-color: white;
}


.comments-textarea {
  font-size: 12pt;
  font-family: var(--font-mono);
  outline: none;
  padding: 10px;
  border: none;
  box-shadow: var(--standard-box-shadow);
  width: calc(100% - 10px); /* 5px margin on both sides */
  height: 500px; /* Initial height */
  resize: vertical; /* Allow resizing */
  placeholder: "Type comments for the student here"; /* Placeholder */
}

/* Grid for toggle buttons */
.toggle-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Space between buttons */
  justify-items: center; /* Center buttons horizontally */
  align-items: center; /* Center buttons vertically */
  margin: 20px 0;
}

/* Styles for toggle buttons */
/* Grid for toggle buttons */
.toggle-grid {
  display: flex; /* Use flexbox for a single-line layout */
  gap: 10px; /* Space between buttons */
  justify-content: center; /* Center the buttons horizontally */
  align-items: center; /* Center buttons vertically */
  margin: 20px 0;
}

/* Styles for toggle buttons */
.toggle-button {
  width: 60px; /* Adjust button size */
  height: 60px;
  font-size: 9px; /* Adjust font size */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color2); /* Default color */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: var(--standard-box-shadow);
  transition: background-color 0.3s ease;
}

/* Active button styles */
.toggle-button.active {
  background-color: var(--color1); /* Highlight color when active */
}

.toggle-button:hover {
  background-color: var(--color3); /* Optional hover effect */
}

