.masterCourseDesignContainer{
/*  background color of variable color2 but 0.2 opacity in rgba */
    background-color: var(--color2verylight);

    /* minimum height of 100vh */
    min-height: 100vh;
}

.input-field {
  box-shadow: var(--standard-box-shadow);
  font-family: var(--font-mono);
  border: none;
  padding: 12px;
  font-size: 18px;
}

/* for question day picker component  */

.sectionName-questionDayPicker, .topicName-questionDayPicker {
  font-family: var(--font-mono);
  font-size: 18px;
  width: 400px;
  box-shadow: var(--standard-box-shadow);
  border: none;
  border-radius: 2px;
  padding: 12px;
  margin-top: 14px;
  text-align: left;
}

.sectionName-questionDayPicker {
  background-color: var(--color2);
  color: white;
}

.topicName-questionDayPicker:not(selected) {
  background-color: white;
  color: black;
}

.topicName-questionDayPicker:not(selected):hover {
  background-color: white;
  color: black;
}

.topicName-questionDayPicker.selected {
  background-color: var(--color3);
  color: black;
}

.topicName-questionDayPicker.selected:hover {
  background-color: var(--color3);
  color: black;
}

.topicName-questionDayPicker:hover {
  opacity: 0.9;
}

.hoverOpacityButton:hover {
  opacity: 0.8;
}


.ViewAndEditUsersTable th {
    padding-right: 20px;
    text-align: left;
}

.ViewAndEditUsersTable td {
    padding-right: 20px;
}


/* for TA student assignment page  */

.ta-student-assignments {
  display: flex;
  gap: 20px;
}

.unassigned-students {
  flex: 1;
  background: white;
  box-shadow: var(--standard-box-shadow);
  padding: 10px;
  font-family: var(--font-secondary);
  font-size: 14px;
}

.unassigned-students .student {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.unassigned-students .student.selected {
  background-color: var(--color3);
}

.ta-buttons {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ta-buttons button {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.assigned-students {
  flex: 1;
}

.ta-assigned-list {
  background: white;
  box-shadow: var(--standard-box-shadow);
  padding: 10px;
  font-family: var(--font-secondary);
  font-size: 14px;
  margin-bottom: 15px;
}

.ta-assigned-list strong {
  font-weight: bold;
}

.student-word-count-ranking{
  background-color: white;
  box-shadow: var(--standard-box-shadow);
  font-family: var(--font-secondary);
  font-size: 16px;
  padding: 20px;
  width: auto;
}

/* Main CourseDesign Menu */
nav {
  display: flex;
  justify-content: flex-start;
  background-color: var(--color1);
  padding: 10px;
  border-bottom: none;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 20;
}

h2 {
  color: var(--color1);
  font-family: var(--font-secondary);
}

nav button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: var(--color2);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--font-small-caps);
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.master-menu-button {
  background-color: var(--color1);
  color: white;
  border-radius: 20px;
  font-size: large;
}

nav button.active {
  background-color: #ffffff;
  color: var(--color1);
}

nav button:hover {
  background-color: #ffffff;
  color: var(--color1);
}

/* General Layout for all Subcomponents */
.subcomponent-wrapper {
  margin-top: 60px;
  padding: 20px;
}

/* Top Menu for Subcomponents */
.subcomponent-top-menu {
  display: flex;
  justify-content: flex-start;
  background-color: var(--color1);
  padding: 10px;
  border-bottom: none;
  z-index: 15;
  position: fixed;
  top: 60px;
  width: 100%;
}

.subcomponent-top-menu button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: var(--color2light);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--font-secondary);
  font-size: 14px;
  transition: background-color 0.3s ease;
}

/* same styles for active and hover */
.subcomponent-top-menu button.active, .subcomponent-top-menu button:hover {
  background-color: white;
  color: var(--color2);
}

.ViewAndEditUsersTable {
  background-color: white;
  box-shadow: var(--standard-box-shadow);
  padding: 20px;
  font-family: var(--font-secondary);
  font-size: 16px;
}

/* Content Area Styling for Subcomponents */
.questionDesignBox {
  padding: 20px;
  background-color: white;
  box-shadow: var(--standard-box-shadow);
  border-radius: 8px;
  margin-top: 10px;
}

.subcomponent-content {
  margin-top: 50px;
  padding: 20px;
}

.question-design-content {
  margin-top: 120px;
  padding: 20px;
}

.rubric-design-display-options {
    margin-top: 16px;
}

.rubric-design-options-container {
    display: flex;
    gap: 16px; /* Space between options */
    margin-top: 12px;
}

.rubric-design-option-div {
    padding: 6px;
    flex: 1; /* Make all options equally wide */
    text-align: center; /* Center-align the text */
    border: none;
    cursor: pointer;
    background-color: #fff;
    box-shadow: var(--standard-box-shadow);
    transition: background-color 0.2s, box-shadow 0.2s;
}

.rubric-design-option-div:hover {
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Slightly deeper shadow on hover */
}

.rubric-design-option-div.rubric-design-selected {
    background-color: var(--color3);
}

.rubric-container.standalone{
  background-color: white;
  padding: 20px;
  font-family: var(--font-secondary);
  margin-top: 30px;
}
/* from MasterQuestion.css */

/* Custom radio button */
.radioButton, .checkboxButton {
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
    display: inline-flex; /* Changed from inline-block to flex */
    align-items: center; /* Aligns items vertically in the center */
    justify-content: center; /* Centers content horizontally */
    margin-right: 10px;
    margin-bottom: 10px;
    max-width: 160px;
    background-color: white;
    border-radius: 1px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
}


.radioButton.selected, .checkboxButton.selected {
    background-color: var(--color3);
}

.questionDesignBox{
  font-size: 16px;
  border-radius: 1.5px;
  width: 900px;
  max-width: 80%;
}

.ordinary-mono-one-line-expanding-textarea{
  font-family: var(--font-mono);
  padding: 8px;
  border: none;
  box-shadow: var(--standard-box-shadow);
  width: 100%;
  resize: none;
  overflow: hidden;
}

.questionTextArea {
    width: 100%; 
    font-family: var(--font-mono);
    padding: 20px;
    border: none;
    box-sizing: border-box;
    border-radius: 1px;
    resize: none; /* Prevent manual resize */
    font-size: 16px;
    background-color: #f8f8f8;
    outline: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    overflow: hidden; /* Hide scrollbar */
}

.logic-hover-display {
    position: fixed;
    background-color: var(--color1light);
    padding: 14px;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: var(--font-primary);
    font-size: 16px;
    color: #333;
    z-index: 1000;
    white-space: pre-wrap;
    max-width: 500px;
}



/* Left Menu Bar for Subcomponents */
.left-menu-bar {
  position: fixed;
  top: 120px;
  left: 0;
  height: calc(100% - 120px);
  width: 220px;
  background-color: var(--color2);
  overflow-y: auto;
  padding-top: 100px;
  max-width: 20%;
}

.left-menu-bar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Align items in a column */
  align-items: flex-end; /* Align items to the right edge */
}

.left-menu-bar li {
  display: inline-block; /* Items adjust width based on content */
  text-align: right; /* Text inside is right-aligned */
  padding: 5px 15px;
  margin-bottom: 12px;
  border: none;
  border-radius: 20px 0 0 20px;
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
}




.left-menu-bar li:hover, .left-menu-bar li.active {
  background-color: var(--color2verylight); 
  color: var(--color1); /* Font color changes on hover */
}

.thesis-item {
    margin-top: 40px; /* Adds space between the regular topics and Theses */
}

/* Question Design Wrapper */
.question-design-wrapper .subcomponent-content, .question-design-content {
  margin-left: 240px;
}

.question-design-wrapper .left-menu-bar {
  background-color: var(--color2light);
}

/* Styling for Inputs and Buttons */


button {
  padding: 10px 20px;
  background-color: var(--color2);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--font-secondary);
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--color2light);
}

/* Switch Styling */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color4);
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--color2);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Toggle Label */
.toggle-label {
  margin-left: 10px;
  font-size: 16px;
  font-family: var(--font-primary);
}
