.grading-statistics {
    background-color: white;
    box-shadow: var(--standard-box-shadow);
    font-family: var(--font-secondary);
    font-size: 16px;
    padding-bottom: 20px; /* Add padding at the bottom to prevent text from looking crunched */
}

.grading-statistics table {
    border-collapse: collapse;
    table-layout: fixed;
}

.grading-statistics th,
.grading-statistics td {
    text-align: center;
    padding: 2px;
}

.grading-statistics th {
    font-weight: bold;
    vertical-align: bottom; /* Align headers to the bottom */
    padding-top: 20px;
    padding-bottom: 5px; /* Reduce bottom padding for headers */
}

.grading-statistics td:first-child,
.grading-statistics th:first-child {
    text-align: left;
    padding-left: 30px;
    min-width: 60px;
    max-width: 160px;
    width: auto; /* Make the left column auto-width */
    white-space: nowrap; /* Prevent text wrapping */
    padding-right: 10px; /* Add some padding for visual spacing */
}

.grading-statistics td:not(:first-child),
.grading-statistics th:not(:first-child) {
    width: 100px; /* Fixed width for all other columns */
}
