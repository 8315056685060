.faq-design-container {
  max-width: 1000px;
  margin: 0 auto;
}

.faq-design-list {
  margin: 20px 0;
}

.faq-design-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.faq-design-question,
.faq-design-answer {
  flex: 1;
  margin-right: 10px;
  padding: 6px;
  padding: 8px;
  font-size: 16px;
  border: none;
  box-shadow: var(--standard-box-shadow);
  border-radius: 4px;
  resize: vertical;
  font-family: var(--font-mono);
}

.faq-design-controls {
  display: flex;
  flex-direction: column;
}

.faq-design-controls button {
  border: none;
  cursor: pointer;
  margin: 2px 0;
}

.add-faq-design-button {
  display: block;
  margin: 20px auto;
  padding: 10px 15px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


