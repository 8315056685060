/* Dropdown container styling */
.student-dropdown {
     font-family: var(--font-secondary); /* Use your secondary font */
  list-style: none; /* No bullet points */
  padding: 0;
  margin: 0;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  position: absolute;
  z-index: 10;
  width: 200px; /* Full width of the input box */
  border-radius: 4px; /* Optional: Rounded corners for a polished look */
}

/* Individual list item */
.student-dropdown li {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out; /* Smooth hover effect */
}

/* Hover effect */
.student-dropdown li:hover {
  background-color: var(--color3); /* Use your app's predefined color variable */
}

/* Input box styling (optional, if not already styled elsewhere) */
.student-search input {
 font-family: var(--font-secondary); /* Use your secondary font */
  width: 200px; /* Ensure the input box matches the dropdown */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-shadow: var(--standard-box-shadow); /* Use your standard shadow variable */
  margin-bottom: 8px; /* Space below input box */
}

.chart-container {
  background-color: white;
  box-shadow: var(--standard-box-shadow);
  padding: 20px;
  border-radius: 8px; /* Optional: Rounded corners for a polished look */
  margin: 20px auto; /* Center and space it */
  max-width: 800px; /* Optional: Limit width */
  height: 400px;
}
