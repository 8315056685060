.student-progress-view {
  padding: 20px;
}

.section-progress {
  background-color: white;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 3px;
  box-shadow: var(--standard-box-shadow);
}

.section-progress h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;
}

.section-progress p {
  margin: 5px 0;
}

.status-icon {
  color: var(--color1);
  font-size: 1.5rem;
  margin-left: 10px;
}

.section-header {
  color: var(--color2);
  font-size: 24px; /* Adjust for larger font */
  font-weight: bold;
}

