



html,body {
    height: 100%;
    min-height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
        overflow-y: scroll; /* makes scroll bar always visible to avoid left margin shift between page views */
  /* prevent font boosting on mobile devices */

}

@media print {
    body, html {
        overflow: visible !important;
        height: auto !important;
    }
}