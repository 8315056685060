.grading-student-list {
    background-color: white;
    box-shadow: var(--standard-box-shadow);
    font-family: var(--font-secondary);
    font-size: 16px;
}

.grading-student-list table {
    border-collapse: collapse;
}

.grading-student-list th,
.grading-student-list td {
    text-align: center;
    padding: 2px;
}

.grading-student-list th {
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 10px;
}

.grading-student-list td:first-child,
.grading-student-list th:first-child {
    text-align: left;
    padding-left: 30px;
}

.grading-student-list td:not(:first-child),
.grading-student-list th:not(:first-child) {
    width: 80px;
}

.view-work-button, .grade-button, .completed-grade-button {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4px;
}

 .completed-grade-button{
    background-color: white;
    color: var(--color2);
    font-size: 14px;
    padding: 2px;
 }

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

/* for spinner to disguise forced page reload */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
