.questionContainer{
     display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 95%;
    padding: 10px;
    background-color: white;
    margin-bottom: 20px;
     /* Minimalist, flat box shadow */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.questionText{
    font-size: 18px;
}

.longAnswerTextForPDF {
  white-space: pre-wrap;
  text-align: left;
  font-family: var(--font-mono);
  background-color: #EEE2DE;
  border-radius: 5px;
  padding: 20px;
  font-size: 16px;
}


/* Style for the radio input (hidden) */
.radioInput, .checkboxInput {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.optionsContainer {
    width: 100%;
    font-family: var(--font-mono);
    font-size: 16px;
}

/* Custom radio button */
.radioButton, .checkboxButton {
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
    display: inline-flex; /* Changed from inline-block to flex */
    align-items: center; /* Aligns items vertically in the center */
    justify-content: center; /* Centers content horizontally */
    margin-right: 10px;
    margin-bottom: 10px;
    max-width: 160px;
    background-color: white;
    border-radius: 1px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}


.radioButton.selected, .checkboxButton.selected {
    background-color: var(--color3);
}

.questionContainer textarea {
    width: 100%; 
    font-family: var(--font-mono);
    padding: 10px; /* Some padding inside the textarea */
    border: none;
    box-sizing: border-box;
    border-radius: 2px; /* Rounded corners */
    resize: vertical; /* Allows vertical resize only */
    font-size: 16px; /* Font size */
    background-color: #f8f8f8; /* Very light shade of grey */
    outline: none; /* Remove outline */
}

.questionContainer.contradictionContainer {
    background-color: var(--color1);
    color: white;
}


