.faq-container {
  background: white;
  box-shadow: var(--standard-box-shadow);
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.faq-item {
  margin-bottom: 15px;
}

.faq-question {
  font-family: var(--font-primary);
  color: var(--color2);
  font-size: 20px;
  margin: 0;
  cursor: pointer;
  transition: color 0.2s ease, font-weight 0.2s ease;
  font-weight: bold;
}

.faq-question:hover {
  color: var(--color1); /* Define this color in your CSS variables */
  font-weight: bold;
}

.faq-answer {
  font-family: var(--font-secondary);
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.faq-answer.visible {
  max-height: 800px; /* Large enough to fit any answer */
  opacity: 1;
}
